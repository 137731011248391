import * as styles from 'src/css/embed.module.css'
import PostCollection from 'src/components/post-collection'
import React from 'react'
import {Helmet} from 'react-helmet'
import {graphql} from 'gatsby'
import EmbedLink from 'src/components/embed-link'
import {useEmbedMessaging} from 'src/utils/embed'

const BlogPostsEmbed = ({data, pageContext}) => {
  const {articles} = data
  const {country} = pageContext || {}

  useEmbedMessaging('blog-posts')

  return (
    <div className={styles.postcards}>
      <Helmet>
        <base target="_parent" />
      </Helmet>
      <PostCollection posts={articles.nodes} titleComponent="h3" linkComponent={EmbedLink} country={country} slider />
    </div>
  )
}

export default BlogPostsEmbed

export const query = graphql`
  query {
    articles: allStrapiArticle(
      filter: {postType: {slug: {eq: "articles"}}}
      sort: {fields: publishedAt, order: DESC}
      limit: 12
    ) {
      nodes {
        ...Postcard
      }
    }
  }
`;